import React from 'react'
import '../styles/media-queries.css'
import styled from 'styled-components'

const StyledDiv = styled.div`
  width: 100%;
  margin: 0 auto;
`

const Container = ({ children }) => {
  return <StyledDiv>{children}</StyledDiv>
}

export default Container
