// for ThemeProvider. Define global variables here
const theme = {
  font: {
    size: { base: '18px' },
    color: {
      black: '#000000',
      white: '#FFFFFF',
      grey: { light: '#888888', medium: '#474747', dark: '#333333' }
    },
    family: {
      base: 'Source Sans Pro, Helvetica Neue, Helvetica,Arial, Sans-Serif'
    }
  },
  color: {
    black: '#000000',
    grey: {
      dark: '#272727',
      light: '#F4F5F5'
    },
    yellow: '#ffc107',
    white: '#FFFFFF'
  }
}

export default theme
