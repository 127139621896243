import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import theme from '../styles/abstracts/theme'
import Header from './header'
import Container from './container'
import Footer from './footer'
import '../styles/layout.css'
import '../styles/media-queries.css'
import '../styles/vars.css'

const Content = styled.div`
  min-height: calc(100% - 73px - 152px);

  @media (--media-min-small) {
    min-height: calc(100% - 90px - 169px);
  }
`

const Layout = ({ children, onHideNav, onShowNav, showNav, siteTitle }) => (
  <ThemeProvider theme={theme}>
    <>
      <Container>
        <Header />
        <Content>{children}</Content>
        <Footer />
      </Container>
    </>
  </ThemeProvider>
)

export default Layout
