import { Link, StaticQuery, graphql } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import logo from '../images/allianceLogo.png'
import { above, below } from '../styles/abstracts/breakpoints.js'

const Root = styled.div`
  background-color: black;
  height: 335px;
  margin: auto;
  color: #fff;
  padding: 50px;
  font-family: Source Sans Pro;
  font-size: 17px;

  @media (max-width: 1000px) {
    font-size: 16px;
  }
  @media (max-width: 850px) {
    font-size: 15px;
    padding-left: 20px;
  }
`
const TitleText = styled.span`
  color: white;
  margin-bottom: 20px;
`
const FooterTop = styled.div`
  height: 90%;
  display: flex;
  flex-direction: row;
`
const InfoColumn = styled.div`
  float: left;
  width: 25%;
  @media (max-width: 1600px) {
    padding-right: 55px;
  }
  @media (max-width: 1000px) {
    width: 30%;
  }
  @media (max-width: 850px) {
    width: 22%;
    margin-right: 50px;
  }
  @media (max-width: 785px) {
    width: 35%;
    padding-right: 0px;
    margin-right: 30px;
  }
`
const FooterColumn = styled.div`
  float: left;
  width: ${props => (props.displayColumns === 2 ? '33%' : '16%')};
  display: flex;
  flex-direction: column;
  @media (max-width: 1000px) {
    width: ${props => (props.displayColumns === 2 ? '30%' : '15%')};
  }
  @media (max-width: 850px) {
    width: ${props => (props.displayColumns === 2 ? '30%' : '15%')};
  }
  @media (max-width: 785px) {
    width: ${props => (props.displayColumns === 2 ? '33%' : '10%')};
    padding-right: 15px;
  }
`
const FooterColumnLower = styled.div`
  ${below.md} {
    column-count: 1;
  }
  column-count: ${props => (props.displayColumns === 2 ? '2' : '1')};
`
const FooterBottom = styled.div`
  height: 10%;
  border-top: 0.5px solid #242424;
  padding-top: 30px;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  display: inline-block;
`
const LogoWrapper = styled.div`
  height: 46px;
  min-width: 125px;
  background-image: url(${logo});
  background-size: 161px 46px;
  background-repeat: no-repeat;
  ${below.md} {
    height: 37px;
    background-size: 125px 37px;
  }
  @media (max-width: 350px) {
    height: 32px;
    background-size: 108px 32px;
  }
`
const Footer = props => {
  return (
    <StaticQuery
      query={graphql`
        query FooterQuery {
          sanityContactInfo {
            phoneNumber
            fullAddress
            mapsUrl
          }
          sanitySocial {
            facebook
            facebookIcon {
              asset {
                url
              }
            }
            instagram
            youTube
            youTubeIcon {
              asset {
                url
              }
            }
            instagramIcon {
              _type
              asset {
                url
              }
            }
          }
          sanityFooter {
            columnOneVisible
            columnTwoVisible
            columnThreeVisible
            columnFourVisible
            copyRight
            programs {
              id
              programTitle
              footerDisplay
              slug {
                current
              }
            }
            columnOneTitle
            columnTwoTitle
            columnThreeTitle
            columnFourTitle
            instructors {
              id
              name
              slug {
                current
              }
            }
            columnTwoLinkOneText
            columnTwoLinkOneSlug {
              current
            }
            columnTwoLinkTwoText
            columnTwoLinkTwoSlug {
              current
            }
            apparelItems {
              id
              name
              slug {
                current
              }
            }
            posts {
              id
              title
              slug {
                current
              }
            }
          }
        }
      `}
      render={data => {
        const { sanityContactInfo, sanityFooter, sanitySocial } = data

        const {
          copyRight,
          columnOneVisible,
          columnTwoVisible,
          columnThreeVisible,
          columnFourVisible,
          columnOneTitle,
          columnTwoTitle,
          columnTwoLinkOneText,
          columnTwoLinkOneSlug,
          columnTwoLinkTwoText,
          columnTwoLinkTwoSlug,
          columnThreeTitle,
          columnFourTitle,
          instructors,
          programs,
          apparelItems,
          posts
        } = sanityFooter

        const {
          facebook,
          facebookIcon,
          youTube,
          youTubeIcon,
          instagram,
          instagramIcon
        } = sanitySocial

        let displayColumns = 0
        if (columnOneVisible) displayColumns++
        if (columnTwoVisible) displayColumns++
        if (columnThreeVisible) displayColumns++
        if (columnFourVisible) displayColumns++

        function columnListBuilder(typeArray, dataPathName, columnName) {
          // convert string to obj path
          function index(obj, is, value) {
            if (typeof is === 'string') return index(obj, is.split('.'), value)
            else if (is.length == 1 && value !== undefined) return (obj[is[0]] = value)
            else if (is.length == 0) return obj
            else return index(obj[is[0]], is.slice(1), value)
          }

          const typeArrayList = typeArray.map((item, i) => {
            let display = index(item, dataPathName)
            let linkUrl = item.slug.current

            // Parse instructor/program names for display
            if (columnName == 'instructors') {
              let names = display.split(' ')
              let firstName = names[0][0].toUpperCase() + names[0].slice(1).toLowerCase()
              let lastName = names[1][0].toUpperCase() + names[1].slice(1).toLowerCase()
              display = firstName + ' ' + lastName
              linkUrl = '/instructor/' + item.slug.current
            } else if (columnName == 'programs') {
              display = item.footerDisplay
            }

            return (
              <span key={linkUrl}>
                <StyledLink to={linkUrl}>{display}</StyledLink>
                <br />
                <br />
              </span>
            )
          })

          return <span>{typeArrayList}</span>
        }
        let additionalLinks = <span />
        if (columnTwoLinkOneText && !columnTwoLinkTwoText) {
          additionalLinks = (
            <div>
              <StyledLink to={columnTwoLinkOneSlug.current}>{columnTwoLinkOneText}</StyledLink>
            </div>
          )
        } else if (columnTwoLinkOneText && columnTwoLinkTwoText) {
          additionalLinks = (
            <div>
              <StyledLink to={columnTwoLinkOneSlug.current}>{columnTwoLinkOneText}</StyledLink>
              <br />
              <br />
              <StyledLink to={columnTwoLinkTwoSlug.current}>{columnTwoLinkTwoText}</StyledLink>
            </div>
          )
        }

        const spacerItem = (
          <div>
            <br />
          </div>
        )
        let additionalLinkSpacers
        if (columnTwoLinkOneText && !columnTwoLinkTwoText) {
          additionalLinkSpacers = (
            <span>
              {spacerItem}
              {spacerItem}
              {spacerItem}
            </span>
          )
        } else if (columnTwoLinkOneText && columnTwoLinkTwoText) {
          additionalLinkSpacers = (
            <span>
              {spacerItem}
              {spacerItem}
            </span>
          )
        }
        return (
          <Root>
            <FooterTop>
              <InfoColumn>
                <LogoWrapper />
                <br />
                <br />
                <a
                  href={sanityContactInfo.mapsUrl}
                  target="_blank"
                  style={{ textDecoration: 'none', color: 'white' }}
                >
                  {sanityContactInfo.fullAddress}
                </a>
                <br />
                +1 {sanityContactInfo.phoneNumber}
                <br />
                <br />
                <br />
                <a href={facebook}>
                  <img src={facebookIcon.asset.url} />
                </a>
                &nbsp;&nbsp;&nbsp;
                <a href={youTube}>
                  <img src={youTubeIcon.asset.url} />
                </a>
                &nbsp;&nbsp;&nbsp;
                <a href={instagram}>
                  <img src={instagramIcon.asset.url} />
                </a>
              </InfoColumn>

              {columnOneVisible ? (
                <FooterColumn displayColumns={displayColumns}>
                  <TitleText>
                    <b>{columnOneTitle}</b>
                  </TitleText>
                  <FooterColumnLower displayColumns={displayColumns}>
                    {columnListBuilder(instructors, 'name', 'instructors')}
                    {instructors.length > 3 && !(instructors.length % 2 === 0) ? spacerItem : null}
                  </FooterColumnLower>
                </FooterColumn>
              ) : (
                <div />
              )}

              {columnTwoVisible ? (
                <FooterColumn displayColumns={displayColumns}>
                  <TitleText>
                    <b>{columnTwoTitle}</b>
                  </TitleText>
                  <FooterColumnLower displayColumns={displayColumns}>
                    {columnListBuilder(programs, 'programTitle', 'programs')}
                    {additionalLinks}
                    {additionalLinkSpacers}
                  </FooterColumnLower>
                </FooterColumn>
              ) : (
                <div />
              )}

              {columnThreeVisible ? (
                <FooterColumn displayColumns={displayColumns}>
                  <TitleText>
                    <b>{columnThreeTitle}</b>
                  </TitleText>
                  <FooterColumnLower displayColumns={displayColumns}>
                    {columnListBuilder(apparelItems, 'name')}
                  </FooterColumnLower>
                </FooterColumn>
              ) : (
                <div />
              )}

              {columnFourVisible ? (
                <FooterColumn displayColumns={displayColumns}>
                  <TitleText>
                    <b>{columnFourTitle}</b>
                  </TitleText>
                  <FooterColumnLower displayColumns={displayColumns}>
                    {columnListBuilder(posts, 'title')}
                  </FooterColumnLower>
                </FooterColumn>
              ) : (
                <div />
              )}
            </FooterTop>
            <FooterBottom>{copyRight}</FooterBottom>
          </Root>
        )
      }}
    />
  )
}

export default Footer
