import { Link, StaticQuery, graphql } from 'gatsby'
import React, { Component } from 'react'
import styled from 'styled-components'
import { above, below, breakpointIntegers } from '../styles/abstracts/breakpoints.js'
import HamburgerMenuWrapper from './hamburger-menu-wrapper'
import {
  FaQuestionCircle,
  FaPhone,
  FaMapMarkerAlt,
  FaDollarSign,
  FaEnvelopeOpenText,
  FaEnvelope
} from 'react-icons/fa'

const Root = styled.div`
  position: fixed;
  z-index: 100;
  background-color: ${props => props.theme.color.black};
  width: 100%;
  margin: auto;
  font-size: 14px;
  font-family: ${props => props.theme.font.family.base};
`

const NavWrapper = styled.div`
  box-sizing: border-box;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 1;
  align-self: stretch;
  flex-direction: row;

  ${above.md} {
    height: 81px;
  }
`

const Nav = styled.div`
  display: block;
  width: 100%;

  div {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }

  ul {
    list-style: none;
    display: flex;
    justify-content: flex-end;
    margin: 0;
    padding: 0;
    /* max-width: 60px; */
  }

  ul li a {
    display: block;
    color: inherit;
    text-decoration: none;
    padding: 0.425rem;
    ${below.xl} {
      padding: 0.075rem;
    }
  }

  ul li a:hover {
    color: #ffc107;
  }
`

const HeaderColumn = styled.div`
  float: left;
  height: 100%;
  display: table;
`
const HeaderColumn1 = styled(HeaderColumn)`
  padding-left: 50px;
  padding-right: 2em;

  /* Firefox */
  @-moz-document url-prefix() {
    & {
      padding-top: 0px !important;
    }
  }
  /* Microsoft IE10 and above */
  @media all and (-ms-high-contrast: none) {
    & {
      padding-top: 0px !important;
    }
  }
  /* Microsoft Edge */
  @supports (-ms-ime-align: auto) {
    & {
      padding-top: 0px !important;
    }
  }
`
const HeaderColumn2 = styled(HeaderColumn)`
  /* Firefox */
  @-moz-document url-prefix() {
    & {
      padding-top: 0px !important;
    }
  }
  /* Microsoft IE10 and above */
  @media all and (-ms-high-contrast: none) {
    & {
      padding-top: 0px !important;
    }
  }
  /* Microsoft Edge */
  @supports (-ms-ime-align: auto) {
    & {
      padding-top: 0px !important;
    }
  }
`

const HeaderColumn3 = styled(HeaderColumn)`
  float: right;
  padding: 0px !important;
`

const SignUpWrapper = styled.div`
  float: right;
  height: 82.5%;

  /* Firefox */
  @-moz-document url-prefix() {
    & {
      height: 82.5%;
    }
  }
  /* Microsoft IE10 and above */
  @media all and (-ms-high-contrast: none) {
    & {
      height: 82.5%;
    }
  }
  /* Microsoft Edge */
  @supports (-ms-ime-align: auto) {
    & {
      height: 82.5%;
    }
  }
  display: table;
  background-color: ${props => props.theme.color.yellow};
  margin-left: 2em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 50px;
  padding-right: 50px;
`

const ColumnHelper = styled.div`
  display: table-cell;
  vertical-align: middle;
`

const ColumnHelper2 = styled(ColumnHelper)`
  padding-top: 0.75em !important;
`

const NavBarTextWhite = styled.ul`
  color: ${props => props.theme.font.color.white};
  font-size: 15px;
  font-weight: bold;
`

const NavBarTextGrey = styled.ul`
  color: ${props => props.theme.font.color.grey.light};
  font-size: 15px;
  font-weight: 600;
`

const NavBarTextBlack = styled.ul`
  color: ${props => props.theme.font.color.black};
  font-size: 15px;
  font-weight: 600;
`

const QuestionIcon = styled(FaQuestionCircle)`
  padding: 0.5em;
  padding-top: 0.25em;
  vertical-align: middle;
`

const PhoneIcon = styled(FaPhone)`
  padding: 0.5em;
  padding-right: 0.25em;
  padding-top: 0.25em;
  vertical-align: middle;
`

const MapIcon = styled(FaMapMarkerAlt)`
  padding: 0.5em;
  padding-left: 0;
  padding-top: 0.25em;
  vertical-align: middle;
`

const DollarIcon = styled(FaDollarSign)`
  padding: 0.5em;
  padding-left: 0.25em;
  padding-top: 0.25em;
  vertical-align: middle;
`
const LetterIcon = styled(FaEnvelopeOpenText)`
  padding: 0.5em;
  padding-top: 0.25em;
  vertical-align: middle;
`
const NewsletterIcon = styled(FaEnvelope)`
  padding: 0.5em;
  padding-left: 0.25em;
  padding-top: 0.25em;
  vertical-align: middle;
`
const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
`

class Header extends Component {
  constructor(props) {
    super(props)
    let isMobile = false
    if (typeof window !== `undefined` && window.innerWidth < 1300) {
      isMobile = true
    }
    this.state = { isMobile: isMobile, renderReady: false }
  }

  componentWillMount() {
    if (typeof window !== `undefined`) {
      // if (window.innerWidth < breakpointIntegers.lg) {
      //   this.setState({ isMobile: !this.state.isMobile })
      // }
      window.addEventListener('resize', this.handleResize)
    }
  }
  componentDidMount() {
    this.setState({
      renderReady: true
    })
  }
  componentWillUnmount() {
    if (typeof window !== `undefined`) {
      window.removeEventListener('resize', this.handleResize)
    }
  }
  handleResize = () => {
    if (typeof window !== `undefined`) {
      if (window.innerWidth > 1300 && this.state.isMobile) {
        this.setState({ isMobile: !this.state.isMobile })
      } else if (window.innerWidth < 1300 && !this.state.isMobile) {
        this.setState({ isMobile: !this.state.isMobile })
      }
    }
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query HeaderQuery {
            sanityHeader {
              headerLinkOneText
              headerLinkTwoText
              headerLinkThreeText
              headerLinkFourText
              headerLinkOneSlug {
                current
              }
              headerLinkTwoSlug {
                current
              }
              headerLinkThreeSlug {
                current
              }
              headerLinkFourSlug {
                current
              }
              logo {
                asset {
                  url
                  id
                }
              }
              fAQLinkVisible
              contactLinkVisible
              locationLinkVisible
              signUpLinkVisible
            }
          }
        `}
        render={data => {
          const { sanityHeader } = data

          const {
            headerLinkOneText,
            headerLinkTwoText,
            headerLinkThreeText,
            headerLinkFourText,
            headerLinkOneSlug,
            headerLinkTwoSlug,
            headerLinkThreeSlug,
            headerLinkFourSlug,
            logo,
            fAQLinkVisible,
            contactLinkVisible,
            locationLinkVisible,
            signUpLinkVisible
          } = sanityHeader

          let linkOne = '/' + headerLinkOneSlug.current + '/'
          let linkTwo = '/' + headerLinkTwoSlug.current + '/'
          let linkThree = '/' + headerLinkThreeSlug.current + '/'
          let linkFour = '/' + headerLinkFourSlug.current + '/'
          //Workaround for Gatsby build CSS bug
          if (!this.state.renderReady) {
            return null
          } else {
            return (
              <Root>
                {!this.state.isMobile ? (
                  <NavWrapper>
                    <Nav>
                      <HeaderColumn1>
                        <ColumnHelper>
                          <Link to="/">
                            <img src={logo.asset.url} />
                          </Link>
                        </ColumnHelper>
                      </HeaderColumn1>
                      <HeaderColumn2>
                        <ColumnHelper>
                          <NavBarTextWhite>
                            <li>
                              <Link to={linkOne}>{headerLinkOneText}</Link>
                            </li>
                            <li>
                              <Link to={linkTwo}>{headerLinkTwoText}</Link>
                            </li>
                            <li>
                              <Link to={linkThree}>{headerLinkThreeText}</Link>
                            </li>
                            <li>
                              <Link to={linkFour}>{headerLinkFourText}</Link>
                            </li>
                            <li>
                              <Link to="/afterschool">AFTER-SCHOOL PROGRAM</Link>
                            </li>
                          </NavBarTextWhite>
                        </ColumnHelper>
                      </HeaderColumn2>
                      <HeaderColumn3>
                        <ColumnHelper2>
                          <NavBarTextGrey>
                            {fAQLinkVisible ? (
                              <li>
                                <Link to="/faq/">
                                  FAQ
                                  <QuestionIcon />
                                </Link>
                              </li>
                            ) : (
                              <div />
                            )}

                            {contactLinkVisible ? (
                              <li>
                                <Link to="/contact/">
                                  CONTACT
                                  <PhoneIcon />
                                  <MapIcon />
                                </Link>
                              </li>
                            ) : (
                              <div />
                            )}

                            {locationLinkVisible ? (
                              <li>
                                <Link to="/pricing/">
                                  PRICING
                                  <DollarIcon />
                                </Link>
                              </li>
                            ) : (
                              <div />
                            )}

                            <li>
                              <Link to="/newsletter-signup/">
                                NEWSLETTER
                                <NewsletterIcon />
                              </Link>
                            </li>
                          </NavBarTextGrey>
                        </ColumnHelper2>
                        <SignUpWrapper>
                          <ColumnHelper>
                            <NavBarTextBlack>
                              {signUpLinkVisible ? (
                                <StyledLink to="/book-now/">BOOK NOW</StyledLink>
                              ) : (
                                <div />
                              )}
                            </NavBarTextBlack>
                          </ColumnHelper>
                        </SignUpWrapper>
                      </HeaderColumn3>
                    </Nav>
                  </NavWrapper>
                ) : (
                  <HamburgerMenuWrapper />
                )}
              </Root>
            )
          }
        }}
      />
    )
  }
}

export default Header
