import { Link } from 'gatsby'
import React, { Component } from 'react'
import styled, { keyframes } from 'styled-components'
import { above, below } from '../styles/abstracts/breakpoints.js'
import {
  FaBars,
  FaTimes,
  FaQuestionCircle,
  FaPhone,
  FaMapMarkerAlt,
  FaDollarSign,
  FaEnvelopeOpenText,
  FaEnvelope
} from 'react-icons/fa'
import { IoIosClose } from 'react-icons/io'
import { IconContext } from 'react-icons'
import logo from '../images/allianceLogo.png'

const HamburgerWrapper = styled.div`
  height: 81px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  ${below.md} {
    height: 68px;
  }

  div {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 1em;
    padding-right: 1em;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  ul li a {
    display: block;
    color: inherit;
    text-decoration: none;
  }
`

const HeaderColumn = styled.div`
  float: left;
  height: 100%;
  display: table;
  max-height: 78px;
  padding-left: 50px !important;

  ${below.md} {
    padding-left: 20px !important;
  }
  /* Firefox */
  @-moz-document url-prefix() {
    & {
      padding-top: 0px !important;
    }
  }
  /* Microsoft IE10 and above */
  @media all and (-ms-high-contrast: none) {
    & {
      padding-top: 0px !important;
    }
  }
  /* Microsoft Edge */
  @supports (-ms-ime-align: auto) {
    & {
      padding-top: 0px !important;
    }
  }
`

const HamburgerColumnHelper = styled.div`
  padding-left: 0px !important;
  display: table-cell;
  vertical-align: middle;
`

const fadeInOpacity = keyframes`
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
`

const FadeIn = styled.div`
  position: absolute;
  opacity: 1;
  animation-name: ${fadeInOpacity};
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.5s;
  display: inline;
`

const slide = keyframes`
100% {
  left: 0;
}
`

const SlideIn = styled.div`
  position: absolute;
  left: -100vw;
  animation: ${slide} 0.25s forwards;
  animation-duration: 0.5s;
`

const HeaderBlackOutWithSideBar = styled(SlideIn)`
  width: 100vw;
  height: 81px;
  z-index: 2;
  background-color: ${props => props.theme.color.black};
  top: 0;
  padding-left: 0px !important;

  ${below.md} {
    width: calc(100vw - 28px);
    height: 68px;
  }
`

const SideBarIconHelper = styled(HamburgerColumnHelper)`
  padding-top: 0px !important;
`

const HamburgerButton = styled.button`
  padding-left: 0px;
  background-color: ${props => props.theme.color.black};
  border: none;
  float: left;
  :focus {
    outline: none;
  }
  :active {
    vertical-align: none;
    display: block;
  }
`

const SideBarWrapper = styled(SlideIn)`
  width: 100vw;
  height: 100vh;
  background-color: ${props => props.theme.color.black};
  top: 6em;

  ${below.md} {
    width: calc(100vw - 28px);
    height: 101vh;
    top: -1vh;
  }
`

const SideBarContent = styled.div`
  margin-top: 5em;
  ${below.md} {
    padding-left: 7px !important;
  }
  ${above.md} {
    padding-left: 36px !important;
  }

  div a {
    font-size: 22px;
    text-decoration: none;
  }

  ul {
    list-style-type: none;
  }

  li {
    font-size: 22px;
    padding-top: 22px;
    display: block;
    @media (max-width: 370px) {
      font-size: 20px;
      padding-top: 15px;
    }
  }
`

const MenuTextWhite = styled.ul`
  color: ${props => props.theme.font.color.white};
  font-weight: bold;
`

const MenuTextGrey = styled.ul`
  color: ${props => props.theme.font.color.grey.light};
  font-weight: 600;
`

const QuestionIcon = styled(FaQuestionCircle)`
  padding: 0.5em;
  padding-top: 0.25em;
  vertical-align: middle;
`

const PhoneIcon = styled(FaPhone)`
  padding: 0.5em;
  padding-right: 0.25em;
  padding-top: 0.25em;
  vertical-align: middle;
`

const MapIcon = styled(FaMapMarkerAlt)`
  padding: 0.5em;
  padding-left: 0;
  padding-top: 0.25em;
  vertical-align: middle;
`
const DollarIcon = styled(FaDollarSign)`
  padding: 0.5em;
  padding-right: 0.25em;
  padding-top: 0.25em;
  vertical-align: middle;
`
const LetterIcon = styled(FaEnvelopeOpenText)`
  padding: 0.5em;
  padding-top: 0.25em;
  vertical-align: middle;
`
const NewsletterIcon = styled(FaEnvelope)`
  padding: 0.5em;
  padding-top: 0.25em;
  vertical-align: middle;
`
const SignUpWrapper = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 10%;
  background-color: ${props => props.theme.color.yellow};
  left: -100vw;
  -webkit-animation: ${slide} 0.25s forwards;
  -webkit-animation-duration: 0.5s;
  animation: ${slide} 0.25s forwards;
  animation-duration: 0.5s;

  ${below.md} {
    width: calc(100vw - 28px);
  }

  div {
    margin-left: -1em;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  a {
    color: black;
  }
`

const NavBarTextBlack = styled.div`
  color: black;
  font-weight: bold;
  list-style-type: none;
  display: table;
`

const NavBarListTextBlack = styled.ul`
  color: black;
  font-weight: bold;
  list-style-type: none;
  display: table;
`

const HeaderColumn2 = styled.div`
  float: left;
  height: 100%;
  display: table;

  /* Firefox */
  @-moz-document url-prefix() {
    & {
      padding-top: 0px !important;
    }
  }
  /* Microsoft IE10 and above */
  @media all and (-ms-high-contrast: none) {
    & {
      padding-top: 0px !important;
    }
  }
  /* Microsoft Edge */
  @supports (-ms-ime-align: auto) {
    & {
      padding-top: 0px !important;
    }
  }
`

const HeaderColumn3 = styled.div`
  float: right;

  /*Default height 82.5% for Chrome/Safari */
  height: 82.5%;

  /* Firefox */
  @-moz-document url-prefix() {
    & {
      height: 82.5%;
    }
  }
  /* Microsoft IE10 and above */
  @media all and (-ms-high-contrast: none) {
    & {
      height: 82.5%;
      margin-left: 0px;
    }
  }
  /* Microsoft Edge */
  @supports (-ms-ime-align: auto) {
    & {
      height: 82.5%;
      margin-left: 0px;
    }
  }
  display: table;
  background-color: ${props => props.theme.color.yellow};
  margin-left: 1em;

  @media (max-width: 422px) {
    margin-left: 0px;
  }
  @media (max-width: 370px) {
    max-width: 80px;
  }
  ${above.md} {
    padding-left: 36px !important;
    padding-right: 36px !important;
  }

  ${below.md} {
    padding-left: 6px !important;
    padding-right: 6px !important;
    height: 80%;
    /* Firefox */
    @-moz-document url-prefix() {
      & {
        height: 80%;
      }
    }
    /* Microsoft IE10 and above */
    @media all and (-ms-high-contrast: none) {
      & {
        height: 80%;
      }
    }
    /* Microsoft Edge */
    @supports (-ms-ime-align: auto) {
      & {
        height: 80%;
      }
    }
  }
`

const ColumnHelper = styled.div`
  display: table-cell;
  vertical-align: middle;
`

const LogoStyle = styled.img`
  ${below.md} {
    width: 94px;
  }
  @media (max-width: 350px) {
    width: 85px;
  }
`
const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  :active {
    color: ${props => props.theme.color.yellow};
  }
`
class HamburgerMenuWrapper extends Component {
  constructor(props) {
    super(props)
    this.state = { isOpen: false, renderReady: false }
  }

  toggleMenu = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }
  componentDidMount = () => {
    this.setState({
      renderReady: true
    })
  }
  render() {
    //Workaround for Gatsby build CSS bug
    if (!this.state.renderReady) {
      return null
    } else {
      return (
        <HamburgerWrapper>
          <HeaderColumn>
            <HamburgerColumnHelper>
              <HamburgerButton onClick={() => this.toggleMenu()}>
                {!this.state.isOpen ? (
                  <IconContext.Provider value={{ color: 'white', size: '1.5em' }}>
                    <FaBars />
                  </IconContext.Provider>
                ) : (
                  <IconContext.Provider value={{ color: 'black', size: '1.5em' }}>
                    <FaTimes />
                  </IconContext.Provider>
                )}
              </HamburgerButton>
            </HamburgerColumnHelper>
          </HeaderColumn>
          {this.state.isOpen ? (
            <FadeIn>
              <HeaderBlackOutWithSideBar>
                <HeaderColumn>
                  <SideBarIconHelper>
                    <HamburgerButton onClick={() => this.toggleMenu()}>
                      <IconContext.Provider value={{ color: 'white', size: '1.5em' }}>
                        <FaTimes />
                      </IconContext.Provider>
                    </HamburgerButton>
                  </SideBarIconHelper>
                </HeaderColumn>
              </HeaderBlackOutWithSideBar>
              <SideBarWrapper>
                <SideBarContent>
                  <MenuTextWhite>
                    <li>
                      <StyledLink to="/about/">ABOUT</StyledLink>
                    </li>
                    <li>
                      <StyledLink to="/instructors/">INSTRUCTORS</StyledLink>
                    </li>
                    <li>
                      <StyledLink to="/programs/">PROGRAMS</StyledLink>
                    </li>
                    <li>
                      <StyledLink to="/schedule/">SCHEDULE</StyledLink>
                    </li>
                    <li>
                      <StyledLink to="/afterschool/">AFTER SCHOOL PROGRAM</StyledLink>
                    </li>
                  </MenuTextWhite>
                  <MenuTextGrey>
                    <li>
                      <StyledLink to="/faq/">
                        FAQ
                        <QuestionIcon />
                      </StyledLink>
                    </li>
                    <li>
                      <StyledLink to="/contact/">
                        CONTACT
                        <PhoneIcon />
                        <MapIcon />
                      </StyledLink>
                    </li>
                    <li>
                      <StyledLink to="/pricing/">
                        PRICING
                        <DollarIcon />
                      </StyledLink>
                    </li>
                    <li>
                      <StyledLink to="/covidupdate/">
                        COVID UPDATE
                        <LetterIcon />
                      </StyledLink>
                    </li>
                    <li>
                      <StyledLink to="/newsletter-signup/">
                        NEWSLETTER
                        <NewsletterIcon />
                      </StyledLink>
                    </li>
                  </MenuTextGrey>
                  <SignUpWrapper>
                    <NavBarTextBlack>
                      <Link to="/book-now/">BOOK NOW</Link>
                    </NavBarTextBlack>
                  </SignUpWrapper>
                </SideBarContent>
              </SideBarWrapper>
            </FadeIn>
          ) : (
            <></>
          )}
          <HeaderColumn2>
            <ColumnHelper>
              <Link to="/">
                <LogoStyle src={logo} />
              </Link>
            </ColumnHelper>
          </HeaderColumn2>
          <HeaderColumn3>
            <ColumnHelper>
              <NavBarListTextBlack>
                <li>
                  <Link to="/book-now/">BOOK NOW</Link>
                </li>
              </NavBarListTextBlack>
            </ColumnHelper>
          </HeaderColumn3>
        </HamburgerWrapper>
      )
    }
  }
}

export default HamburgerMenuWrapper
