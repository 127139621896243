export const BREAKPOINTS = {
  sm: '600px',
  md: '785px',
  lg: '1200px',
  xl: '1400px',
  xxl: '1800px',
  xxxl: '2560px',
  fm: '1000px'
}

export const above = {
  sm: `@media (min-width: ${BREAKPOINTS.sm})`,
  md: `@media (min-width: ${BREAKPOINTS.md})`,
  lg: `@media (min-width: ${BREAKPOINTS.lg})`,
  xl: `@media (min-width: ${BREAKPOINTS.xl})`,
  xxl: `@media (min-width: ${BREAKPOINTS.xxl})`,
  xxxl: `@media (min-width: ${BREAKPOINTS.xxxl})`,
  fm: `@media (min-width: ${BREAKPOINTS.fm})`
}

export const below = {
  sm: `@media (max-width: ${BREAKPOINTS.sm})`,
  md: `@media (max-width: ${BREAKPOINTS.md})`,
  lg: `@media (max-width: ${BREAKPOINTS.lg})`,
  xl: `@media (max-width: ${BREAKPOINTS.xl})`,
  xxl: `@media (max-width: ${BREAKPOINTS.xxl})`,
  xxxl: `@media (max-width: ${BREAKPOINTS.xxxl})`,
  fm: `@media (max-width: ${BREAKPOINTS.fm})`
}

export const breakpointIntegers = {
  sm: 600,
  md: 785,
  lg: 1200,
  xl: 1400,
  xxl: 1800,
  xxxl: 2560,
  fm: 1000
}
